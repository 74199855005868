<div class="wrapper">
    <div class="content">
        <div class="w-full">
            <h1><span class="highlight">XENOX SEARCH</span> - THE #1 DATA BREACH TOOL</h1>
        </div>
        <div class="flex align-items-center justify-content-between w-full mt-6">
            <div class="flex flex-column w-8">
                <p><span class="white-highlight">Xenox Search,</span> a product by Xenox Security that give you the possibility to lookup any email/username for leaked credentials.</p>
                <div class="flex justify-content-start align-items-center w-11 mt-3">
                    <div class="get-started flex align-items-center justify-content-center mr-3" (click)="navigateToShop()"><h4>Get Started</h4></div>
                    <h3><span class="white-highlight">Multiple APIs</span></h3>
                </div>
            </div>
            <div class="ml-5">
                <img class="attack-hub" src="../../../assets/images/attack-hub.png">
            </div>
        </div>
        <div class="splitter"></div>
        <div class="flex justify-content-between w-full mb-7" style="margin-top: 30px;">
            <div class="info-box">
                <img src="../../../assets/images/multiple-apis-image.png" style="height: 80px; margin-right: 10px;">
                <div class="flex flex-column justify-content-between mt-0">
                    <p><span class="white-highlight">5+</span> APIs</p>
                    <p style="font-size: 10px;">We've got more than 5 different APIs to make sure you get the results you're looking for.</p>
                </div>
            </div>
            <div class="info-box">
                <img src="../../../assets/images/quick-instant-image.png" style="height: 80px; margin-right: 10px;">
                <div class="flex flex-column justify-content-between mt-0">
                    <p><span class="white-highlight">Unlimited </span>Searches</p>
                    <p style="font-size: 10px;">You can search as many breach as you want, there is no daily limit.</p>
                </div>
            </div>
            <div class="info-box">
                <img src="../../../assets/images/cross-platform-image.png" style="height: 80px; margin-right: 10px;">
                <div class="flex flex-column justify-content-between mt-0">
                    <p><span class="white-highlight">Cross</span> Platforms</p>
                    <p style="font-size: 10px;">Xenox Search requires no download and is used through Discord or Telegram.</p>
                </div>
            </div>
        </div>
        <div class="w-full flex justify-content-start">
            <h1 >OUR PLANS</h1>
        </div>
        <div class="w-full flex justify-content-between mb-8">
            <div class="product-box flex flex-column justify-content-between p-3" data-sellix-product="652e9b566e1bb" type="submit" alt="Buy Now with sellix.io">
                <div class="h-6 flex justify-content-between align-items-center">
                    <div class="flex align-items-center">
                        <i class="pi pi-verified mr-2" style="font-size: 1.5rem; color: white;"></i>
                        <p><span class="white-highlight">Premium </span>(Weekly)</p>
                    </div>
                    <div class="product-box-price">
                        <p><span class="white-highlight">15.00€</span></p>
                    </div>
                </div>
                <div class="w-full flex align-items-center justify-content-between">
                    <div class="flex align-items-center">
                        <i class="pi pi-check-square mr-2" style="font-size: 1rem; color: white;"></i>
                        <p style="font-size: 0.75rem;"><span class="white-highlight">Instant </span>Results</p>
                    </div>
                    <div class="flex align-items-center">
                        <i class="pi pi-check-square mr-2" style="font-size: 1rem; color: white;"></i>
                        <p style="font-size: 0.75rem;"><span class="white-highlight">Unlimited  </span>Searches</p>
                    </div>
                    <div class="flex align-items-center">
                        <i class="pi pi-check-square mr-2" style="font-size: 1rem; color: white;"></i>
                        <p style="font-size: 0.75rem;"><span class="white-highlight">No  </span>logs</p>
                    </div>
                </div>
            </div>
            <div class="product-box-best flex flex-column justify-content-between p-3" data-sellix-product="652e9b763f44f" type="submit" alt="Buy Now with sellix.io">
                <div class="h-6 flex justify-content-between align-items-center">
                    <div class="flex align-items-center">
                        <i class="pi pi-star mr-2" style="font-size: 1.5rem; color: white;"></i>
                        <p><span class="white-highlight">Elite </span>(Monthly)</p>
                    </div>
                    <div class="product-box-price-best">
                        <p><span class="white-highlight">25.00€</span></p>
                    </div>
                </div>
                <div class="w-full flex align-items-center justify-content-between">
                    <div class="flex align-items-center">
                        <i class="pi pi-check-square mr-2" style="font-size: 1rem; color: white;"></i>
                        <p style="font-size: 0.75rem;"><span class="white-highlight">Instant </span>Results</p>
                    </div>
                    <div class="flex align-items-center">
                        <i class="pi pi-check-square mr-2" style="font-size: 1rem; color: white;"></i>
                        <p style="font-size: 0.75rem;"><span class="white-highlight">Unlimited  </span>Searches</p>
                    </div>
                    <div class="flex align-items-center">
                        <i class="pi pi-check-square mr-2" style="font-size: 1rem; color: white;"></i>
                        <p style="font-size: 0.75rem;"><span class="white-highlight">No </span>logs</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full mt-6 flex justify-content-center">
            <img src="../../../assets/images/banner.gif">
        </div>
    </div>
</div>
