<div class="wrapper">
    <div class="content">
        <div class="w-full">
            <h1>SMS <span class="highlight">CANNON</span> - THE #1 SMS BOMBER</h1>
        </div>
        <div class="flex align-items-center justify-content-between w-full mt-6">
            <div class="flex flex-column w-8">
                <p><span class="white-highlight">SMS Cannon </span>is the leading SMS bomber/nuker that can destroy any inbox with more than <span class="white-highlight">5K messages </span>per attack.</p>
                <div class="flex justify-content-start align-items-center w-11 mt-3">
                    <div class="get-started flex align-items-center justify-content-center mr-3" (click)="navigateToShop()"><h4>Get Started</h4></div>
                    <h3><span class="white-highlight">Multiple Carriers</span></h3>
                </div>
            </div>
            <div class="ml-5">
                <img class="attack-hub" src="../../../assets/images/attack-hub.png">
            </div>
        </div>
        <div class="splitter"></div>
        <div class="flex justify-content-between w-full mb-7" style="margin-top: 30px;">
            <div class="info-box">
                <img src="../../../assets/images/sms-inbox-image.png" style="height: 80px; margin-right: 10px;">
                <div class="flex flex-column justify-content-between mt-0">
                    <p><span class="white-highlight">High</span> Delivery Rates</p>
                    <p style="font-size: 10px;">We easily get around spam filters using our specially designed techniques.</p>
                </div>
            </div>
            <div class="info-box">
                <img src="../../../assets/images/quick-instant-image.png" style="height: 80px; margin-right: 10px;">
                <div class="flex flex-column justify-content-between mt-0">
                    <p><span class="white-highlight">Quick</span> & <span class="white-highlight">Instant</span></p>
                    <p style="font-size: 10px;">You have the power to ruin any phone with one command.</p>
                </div>
            </div>
            <div class="info-box">
                <img src="../../../assets/images/cross-platform-image.png" style="height: 80px; margin-right: 10px;">
                <div class="flex flex-column justify-content-between mt-0">
                    <p><span class="white-highlight">Cross</span> Platforms</p>
                    <p style="font-size: 10px;">SMS Cannon requires no download and is used through Discord or Telegram.</p>
                </div>
            </div>
        </div>
        <div class="w-full flex justify-content-start">
            <h1 >OUR PLANS</h1>
        </div>
        <div class="w-full flex justify-content-between mb-8">
            <div class="product-box flex flex-column justify-content-between p-3" data-sellix-product="652e9acf28b2d" type="submit" alt="Buy Now with sellix.io">
                <div class="h-6 flex justify-content-between align-items-center">
                    <div class="flex align-items-center">
                        <i class="pi pi-verified mr-2" style="font-size: 1.5rem; color: white;"></i>
                        <p><span class="white-highlight">Premium </span>(Lifetime)</p>
                    </div>
                    <div class="product-box-price">
                        <p><span class="white-highlight">30.00€</span></p>
                    </div>
                </div>
                <div class="w-full flex align-items-center justify-content-between">
                    <div class="flex align-items-center">
                        <i class="pi pi-check-square mr-2" style="font-size: 1rem; color: white;"></i>
                        <p style="font-size: 0.75rem;"><span class="white-highlight">~200 </span>SMS</p>
                    </div>
                    <div class="flex align-items-center">
                        <i class="pi pi-check-square mr-2" style="font-size: 1rem; color: white;"></i>
                        <p style="font-size: 0.75rem;"><span class="white-highlight">Unlimited  </span>Hits</p>
                    </div>
                    <div class="flex align-items-center">
                        <i class="pi pi-check-square mr-2" style="font-size: 1rem; color: white;"></i>
                        <p style="font-size: 0.75rem;"><span class="white-highlight">Queued </span>Attacks</p>
                    </div>
                </div>
            </div>
            <div class="product-box-best flex flex-column justify-content-between p-3" data-sellix-product="652e9b1cad984" type="submit" alt="Buy Now with sellix.io">
                <div class="h-6 flex justify-content-between align-items-center">
                    <div class="flex align-items-center">
                        <i class="pi pi-star mr-2" style="font-size: 1.5rem; color: white;"></i>
                        <p><span class="white-highlight">Elite </span>(Lifetime)</p>
                    </div>
                    <div class="product-box-price-best">
                        <p><span class="white-highlight">100.00€</span></p>
                    </div>
                </div>
                <div class="w-full flex align-items-center justify-content-between">
                    <div class="flex align-items-center">
                        <i class="pi pi-check-square mr-2" style="font-size: 1rem; color: white;"></i>
                        <p style="font-size: 0.75rem;"><span class="white-highlight">~5K </span>SMS</p>
                    </div>
                    <div class="flex align-items-center">
                        <i class="pi pi-check-square mr-2" style="font-size: 1rem; color: white;"></i>
                        <p style="font-size: 0.75rem;"><span class="white-highlight">Unlimited  </span>Hits</p>
                    </div>
                    <div class="flex align-items-center">
                        <i class="pi pi-check-square mr-2" style="font-size: 1rem; color: white;"></i>
                        <p style="font-size: 0.75rem;"><span class="white-highlight">Instant </span>Attacks</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full mt-6 flex justify-content-center">
            <img src="../../../assets/images/banner.gif">
        </div>
    </div>
</div>

