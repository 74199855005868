import { Component } from '@angular/core';

@Component({
  selector: 'app-xenox-sms',
  templateUrl: './xenox-sms.component.html',
  styleUrls: ['./xenox-sms.component.scss']
})
export class XenoxSmsComponent {
  navigateToShop() {
    window.open('https://xenoxsecurity.mysellix.io/', '_blank');
  }
}
