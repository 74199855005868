import { Component } from '@angular/core';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent {
  navigateToDiscord() {
    window.open('https://discord.gg/xenox', '_blank');
  }

  navigateToTelegram() {
      window.open('https://t.me/maniacsecurity', '_blank');
  }

  navigateToShop() {
    window.open('https://xenoxsecurity.mysellix.io/', '_blank');
  }
}
