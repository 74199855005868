import { Component } from '@angular/core';

@Component({
  selector: 'app-xenox-search',
  templateUrl: './xenox-search.component.html',
  styleUrls: ['./xenox-search.component.scss']
})
export class XenoxSearchComponent {
  navigateToShop() {
    window.open('https://xenoxsecurity.mysellix.io/', '_blank');
  }
}
