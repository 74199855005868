import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TabMenuModule } from "primeng/tabmenu";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HomepageComponent } from './components/homepage/homepage.component';
import { XenoxEmailComponent } from './components/xenox-email/xenox-email.component';
import { XenoxSmsComponent } from './components/xenox-sms/xenox-sms.component';
import { XenoxSearchComponent } from './components/xenox-search/xenox-search.component';
import { BackToMenuComponent } from './components/shared-components/back-to-menu/back-to-menu.component';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    XenoxEmailComponent,
    XenoxSmsComponent,
    XenoxSearchComponent,
    BackToMenuComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TabMenuModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
