import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './components/homepage/homepage.component';
import { XenoxEmailComponent } from './components/xenox-email/xenox-email.component';
import { XenoxSmsComponent } from './components/xenox-sms/xenox-sms.component';
import { XenoxSearchComponent } from './components/xenox-search/xenox-search.component';

const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'email', component: XenoxEmailComponent },
  { path: 'sms', component: XenoxSmsComponent },
  { path: 'search', component: XenoxSearchComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
