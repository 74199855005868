import { Component } from '@angular/core';

@Component({
  selector: 'app-xenox-email',
  templateUrl: './xenox-email.component.html',
  styleUrls: ['./xenox-email.component.scss']
})
export class XenoxEmailComponent {
  navigateToShop() {
    window.open('https://xenoxsecurity.mysellix.io/', '_blank');
  }
}
