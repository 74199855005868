<div class="content mt-8">
<p> OUR DISCORD IS BACK JOIN NOW DISCORD.GG/XENOX</p>
    <div class="menu">
        <div class="row">
            <div class="menu-item email" routerLink="/email">
                <img src="../../../assets/icons/email-icon.png">
                <p>EMAIL BOMBER</p>
            </div>
            <div class="menu-item sms" routerLink="/sms">
                <img src="../../../assets/icons/sms-icon.png">
                <p>SMS BOMBER</p>
            </div>
            <div class="menu-item search" routerLink="/search">
                <img src="../../../assets/icons/search-icon.png">
                <p>SEARCH</p>
            </div>
            <div class="menu-item coming-soon">
                <img src="../../../assets/icons/coming-soon-icon.png">
                <p>COMING SOON</p>
            </div>
        </div>
        <div class="row">
            <div class="menu-item discord" (click)="navigateToDiscord()">
                <img src="../../../assets/icons/discord-icon.png">
                <p>DISCORD</p>
            </div>
            <div class="menu-item telegram" (click)="navigateToTelegram()">
                <img src="../../../assets/icons/telegram-icon.png">
                <p>TELEGRAM</p>
            </div>
            <div class="menu-item shop" (click)="navigateToShop()">
                <img src="../../../assets/icons/shop-icon.png">
                <p>SHOP</p>
            </div>
            <div class="menu-item coming-soon">
                <img src="../../../assets/icons/coming-soon-icon.png">
                <p>COMING SOON</p>
            </div>
        </div>
    </div>
</div>
