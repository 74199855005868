import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router, Event as RouterEvent, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'XENOX';
  items: MenuItem[] | undefined;
  isNotHomepage: boolean = false;

  constructor(private router: Router) {}

  ngOnInit() {
    this.items = [
      {
        label: 'Home',
        routerLink: '/'
      },
      {
        label: 'Email',
        routerLink: '/email'
      },
      {
        label: 'Sms',
        routerLink: '/sms'
      },
      {
        label: 'Search',
        routerLink: '/search'
      }
    ];

    this.router.events.pipe(
      // Use filter operator to listen only to NavigationEnd events
      filter((event: RouterEvent): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // Now TypeScript knows `event` is definitely NavigationEnd
      this.isNotHomepage = event.urlAfterRedirects !== '/';
    });
  }
}
